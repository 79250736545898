body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.carousel-item {
  max-width: 100%;
  max-height: 85vh;
  background-color: #4a4a4a;
}

.carousel-item img {
  max-height: 82vh;
  min-height: 43vh;
  height: auto;
  width: 100%;
  object-fit: cover;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.carousel-indicators {
  margin-right: 20% !important;
  margin-left: 20% !important;
}

#modalImage {
  height: auto;
  width: 100%;
}

#about {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 5%;
  padding-right: 5%;
  text-align: center;
  background-color: rgba(99, 61, 26, 0.5);
}

h1 {
  text-decoration: underline;
  text-transform: uppercase;
  text-shadow: 3px 2px rgba(99, 61, 26, 0.8);
  padding-bottom: 15px;
}

h4 {
  padding-bottom: 15px;
}

#contact {
  /*background-color: rgba(99,61,26,.7);*/
  padding-top: 10px;
  padding-left: 10px;
  padding-bottom: 10px;
}

hr.style1 {
  border-top: 3px solid rgba(99, 61, 26);
}

li {
  display: inline;
  padding-inline-end: 15px;
}

#clogo {
  margin-right: 5px;
}

#social,
#mail,
#phone {
  padding-inline-start: 1px;
  padding-bottom: 10px;
  margin-bottom: 0;
}

#prod {
  height: auto;
  width: 100%;
}

#title {
  text-align: center;
}

.carousel-caption {
  border: 1px solid rgba(0, 0, 0, 0);
  border-radius: 30px;
  background-color: rgba(99, 61, 26, 0.5);
}
